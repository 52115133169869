@import "libs/shared/styles/app-theme";

.cesium-widget-credits {
    padding-left: 8px;

    .cesium-credit-logoContainer a[href="https://cesium.com/"]
    {
        display: none;
    }
}
